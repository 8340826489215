import React from "react";
import {
  Box,
  VStack,
  FormControl,
  Input,
  Textarea,
  Stack,
  Text,
  Divider,
  Center,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";

const Contact = () => {
  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <VStack spacing={10}>
      {/* Contact Section */}
      <Box w="100%" pt="160px" pb="20px" color="white">
        <Center>
          <Stack spacing={4} maxWidth="1200px">
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">
                Contact
              </Text>
            </Box>
            <Stack direction={direction} spacing={2} align="center">
              <Text fontSize="md">
                <b>NO</b> (+47) 46 761 761
              </Text>
              <Text fontSize="md">
                <b>@</b> info@firegrader.com
              </Text>
            </Stack>
            <Divider orientation="horizontal" />
          </Stack>
        </Center>
      </Box>

      {/* Form Section */}
      <Box pt="20px" pb="80px" w="80%">
        <FormControl>
          <Input bg="white" placeholder="Name" />
          <Input
            bg="white"
            placeholder="Email (we will never share it)"
            mt="20px"
          />
          <Input bg="white" placeholder="Subject" mt="20px" />
          <Textarea bg="white" placeholder="Message" h="400px" mt="20px" />
          <Button size="lg" mt="20px">
            Submit
          </Button>
        </FormControl>
      </Box>
    </VStack>
  );
};

export default Contact;
